.single-year {
    .ant-picker {
        font-size: 13px;
        .ant-picker-input > input {
            font-size: 13px;
        }
        width: 100%;
        border: 0px !important;
        padding-right: 0px;
        .ant-picker-active-bar {
            display: none;
        }

        .ant-picker-clear {
            opacity: 1;
            right: 35px;
        }
    }
}
