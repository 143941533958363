.select-popover-overlay {
    .ant-popover-inner-content {
        padding: 0px;
    }

    .select-popover-wrap-search {
        width: 100%;
        padding: 10px;
        border-bottom: 1px dashed #ddd;


        .select-popover-search {
            display: flex;
            border: 1px solid #ddd;
            border-radius: 3px;

            &:focus-within {
                border-color: var(--main-color);
            }

            .select-popover-search-input {
                flex: 1;
                height: 30px;

                input {
                    width: 100%;
                    height: 100%;
                    border: 0;
                    outline: 0;
                    padding-left: 5px;
                    padding-right: 5px;
                    background: transparent;
                }
            }

        }
    }


    .item-select {
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 5px 8px;
        position: absolute;
        left: 0px;
        top: 0px;
        height: 48px;
        width: 100%;

        &:hover {
            background: var(--main-color-hover-option-selected);
        }

        .item-select-left {
            width: 48px;
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: center;

            .item-select-left-content {
                height: 32px;
                width: 32px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #e1ecff;
                color: var(--main-color);
                font-size: 12px;
            }
        }


        .item-select-right {
            flex: 1 1 0%;
            line-height: 14px;
            padding-right: 10px;
            padding-left: 0px;
            overflow: hidden;

            .item-select-right-name {
                color: rgba(0, 0, 0, 0.9);
                font-size: 13px;
                display: inline-block;
                width: 100%;
                white-space: nowrap;
                overflow: hidden !important;
                text-overflow: ellipsis;
            }
        }
    }
}

.select-popover {

    .display-circle {
        display: flex;
        align-items: center;

        &.display-row-reverse {
            flex-direction: row-reverse;
            justify-content: flex-end;
        }
    }

    .display-tag {
        min-height: 27px;
    }

    .value-item-tag {
        border: 1px solid rgb(221, 221, 221);
        border-radius: 3px;
        margin-right: 9px;
        font-size: 12px;
        margin-bottom: 3px;
        position: relative;
        height: 20px;
        line-height: 20px;
        display: inline-block;

        &:hover {
            .value-item-remove {
                display: flex;
            }
        }

        .value-item-remove {
            height: 14px;
            width: 14px;
            border-radius: 3px;
            color: rgb(255, 255, 255);
            background: #818181;
            position: absolute;
            top: -7px;
            right: -7px;
            display: none;
            align-items: center;
            justify-content: center;
            border: 1px solid #818181;
            cursor: pointer;
            font-size: 10px;
        }
    }

    .value-item-text {
        overflow: hidden;
        width: 100%;
        display: inline-block;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
        padding: 0px 5px;
        text-align: center;
    }


    .value-item-circle {
        border: 1px solid transparent;
        border-radius: 3px;
        margin-right: 9px;
        font-size: 12px;
        position: relative;
        height: 30px;
        width: 30px;
        border-radius: 50%;
        line-height: 30px;
        display: inline-block;

        &.overlap {
            margin-right: 0px;
            margin-left: -5px;
            background: #e3f2fd;
        }

        &:hover {
            .value-item-remove {
                display: flex;
            }
        }

        .value-item-text {
            text-transform: uppercase;
            padding: 0px !important;
        }

        .value-item-remove {
            height: 14px;
            width: 14px;
            border-radius: 50%;
            color: rgb(255, 255, 255);
            background: #818181;
            position: absolute;
            top: -5px;
            right: -5px;
            display: none;
            align-items: center;
            justify-content: center;
            border: 1px solid #818181;
            cursor: pointer;
            font-size: 10px;
            z-index: 99;
        }
    }



    .select-popover-trigger {
        cursor: pointer;
        display: inline-block;
        vertical-align: top;

        &.select-trigger-circle {
            border: 1px solid rgb(221, 221, 221);
            border-radius: 3px;
            font-size: 12px;
            position: relative;
            height: 30px;
            width: 30px;
            border-radius: 50%;
            line-height: 30px;
            display: inline-block;
            background: #ffffff;
            text-align: center;

            &.select-trigger-overlap {
                margin-left: -10px;
            }
        }

        &:hover {
            color: var(--main-color);
            border-color: var(--main-color);
        }
    }
}



.circle-more-popover {

    .circle-more-popover-row {
        &:hover {
            background: var(--main-color-hover-option-selected);
        }
    }

    .ant-popover-inner-content {
        padding: 0px;
    }

    .value-item-circle {
        border: 1px solid transparent;
        border-radius: 3px;
        margin-right: 9px;
        font-size: 12px;
        position: relative;
        height: 30px;
        width: 30px;
        border-radius: 50%;
        line-height: 30px;
        display: inline-block;

        &.overlap {
            margin-right: 0px;
            margin-left: -5px;
            background: #e3f2fd;
        }

        &:hover {
            .value-item-remove {
                display: flex;
            }
        }

        .value-item-text {
            text-transform: uppercase;
        }

        .value-item-remove {
            height: 14px;
            width: 14px;
            border-radius: 50%;
            color: rgb(255, 255, 255);
            background: #818181;
            position: absolute;
            top: -5px;
            right: -5px;
            display: none;
            align-items: center;
            justify-content: center;
            border: 1px solid #818181;
            cursor: pointer;
            font-size: 10px;
            z-index: 99;
        }
    }

    .item-select-left {
        width: 48px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;

        .item-select-left-content {
            height: 32px;
            width: 32px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #e1ecff;
            color: var(--main-color);
            font-size: 11px;
        }
    }
}