.date-picker-container-is-panel {
    border: 1px solid #ddd;
    border-radius: 3px;

    .is-panel-label-wrap {
        color: #172b4d !important;
        font-weight: 600;
        font-size: 13px;
    }

    .is-panel-label-start,
    .is-panel-label-end,
    .is-panel-label-duration {
        margin-bottom: 5px;
        font-weight: 600;
    }

    .ant-picker-dropdown-range {
        left: unset !important;
        top: 0 !important;
        padding: 0px !important;

        .ant-picker-range-arrow {
            display: none;
        }
    }

    .ant-picker-panel-container {
        box-shadow: none !important;

        .ant-picker-panel {
            border-bottom: 0px !important;
        }
    }

    div:last-child {
        position: relative !important;
    }

    .ant-picker-active-bar,
    .ant-picker-range-separator,
    .ant-picker-suffix {
        display: none;
    }

    .ant-picker-input {
        border: 1px solid #ddd;
        border-radius: 3px;
        height: 30px;

        input {
            padding: 10px;
            font-size: var(--main-input-font-size);
        }

        &:first-child {
            margin-right: 10px;
            position: relative;
        }

        // &:nth-child(2) {
        //     margin-right: 10px;
        // }
    }

    .ant-picker-range {
        border: 0px !important;
        padding: 0px !important;
        box-shadow: none !important;
    }
}

.date-range {
    .ant-picker {
        font-size: 13px;
        .ant-picker-input > input {
            font-size: 13px;
        }
        width: 100%;
        border: 0px !important;
        padding-right: 0px;
        .ant-picker-active-bar {
            display: none;
        }

        .ant-picker-clear {
            opacity: 1;
            right: 35px;
        }
    }
}
