.base-filter {
  margin: 3px 0;
  width: 100%;
  background: #fff;
  border-radius: var(--main-input-border-radius);

  .base-filter-input-wrapper {
    position: relative;
    display: flex;
    padding: 5px;

    .base-filter-input {
      border-radius: var(--main-input-border-radius);
      border: 1px solid var(--main-input-border-color-default);
      margin: 5px;
      width: calc(100% - 10px);
      padding: 5px;
      outline: 0;


    }

    .base-filter-icon {
      font-size: 16px;
      position: absolute;
      top: 12px;
      right: 15px;
    }

    &:hover {
      .base-filter-input {
        border-color: var(--main-input-border-color);
      }

      .base-filter-icon {
        color: var(--main-input-border-color);
      }
    }
  }
}