.icon-picker-overlay {
    max-width: 285px !important;
    z-index: 999;

    .ant-popover-inner-content {
        padding: 10px;
        max-height: 460px !important;
        overflow: auto;
    }

    .button-color {

        display: flex;
        align-items: center;

        .rc-color-picker-wrap {

            display: flex;
            align-items: center;
        }
    }

    .icon-cell {
        padding: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border-radius: 3px;
        font-size: 18px;
        margin: 5px;

        &:hover {
            background-color: var(--main-color);
            color: #fff;
        }
    }

    .icon-picker-pagining {
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 10px;

        .button-previous {
            width: 25px;
            height: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 3px;
            border: 1px solid #ddd;
            font-size: 13px;
            cursor: pointer;

            &:hover {
                background: var(--main-color);
                color: #fff;
                border-color: var(--main-color);
            }
        }

        .button-next {
            width: 25px;
            height: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 3px;
            border: 1px solid #ddd;
            font-size: 13px;
            cursor: pointer;

            &:hover {
                background: var(--main-color);
                color: #fff;
                border-color: var(--main-color);
            }
        }
    }
}

.icon-picker {
    display: flex;
    align-items: center;
    cursor: pointer;


    .icon-picker-remove {
        position: absolute;
        height: 16px;
        width: 16px;
        border-radius: 50%;
        color: red;
        z-index: 1;
        top: -6px;
        right: 3px;
        background: #fff;
        display: none;
        align-items: center;
        justify-content: center;
        font-size: 10px;
        border: 1px solid #ddd;
    }

    .icon-picker-wrap-color {
        width: 32px;
        display: flex;
        align-items: center;
        justify-content: center;

        .rc-color-picker-wrap {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }


    .icon-picker-wrap-menu {
        width: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .icon-cell-value {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border-radius: 3px;
        font-size: 18px;
        margin-right: 5px;
    }

    &:hover {
        .icon-picker-text {
            color: var(--main-color)
        }

        .icon-picker-remove {
            display: flex;
        }
    }


}