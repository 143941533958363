$rdl-input-bg: #fff !default;
$rdl-input-disabled-bg: #eee !default;
$rdl-input-border: #ccc !default;
$rdl-input-color: #333 !default;
$rdl-btn-bg: $rdl-input-bg !default;
$rdl-btn-border: $rdl-input-border !default;
$rdl-btn-color: #333 !default;
$rdl-line-height: 1.428571429 !default;
.react-dual-listbox {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    * {
        box-sizing: border-box;
    }
    input,
    select {
        &:disabled {
            background: $rdl-input-disabled-bg;
            cursor: not-allowed;
        }
    }
    button,
    select {
        line-height: $rdl-line-height;
        font-family: inherit;
    }
}

.rdl-listbox {
    display: flex;
    flex: 1 1 0;
    flex-direction: column;
    align-self: stretch;
}

.rdl-filter-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.rdl-filter {
    padding: 0px 10px;
    height: 32px;
    border: 0px;
    outline: 0px !important;
    box-shadow: none !important;
    flex: 1;
    background: transparent;
}

.rdl-control {
    display: block;
    border: 0px solid $rdl-input-border !important;
    background: transparent;
    border-radius: 2px;
    width: 100%;
    color: $rdl-input-color;
    font-size: 14px;
    outline: none !important;
    option {
        padding: 8px 10px;
        font-size: 13px;
        &:hover,
        &:focus,
        &:active,
        &::selection,
        &:checked {
            background: var(--main-color-hover-option-selected) !important;
            // color: #fff;
        }
    }
}

.rdl-control-container {
    display: flex;
    flex: 1 0 auto;
    padding: 1px;
}

.rdl-control-label {
    position: absolute;
    clip: rect(0 0 0 0);
}

.rdl-control {
    flex: 1 0 auto;
    optgroup {
        font: inherit;
        font-weight: 700;
    }
}

.rdl-actions {
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    margin: 0 10px; // Add search input height and margin
    .rdl-has-filter & {
        padding-top: 44px;
    }
}

.rdl-actions-right,
.rdl-actions-left {
    display: flex;
    flex-direction: column;
}

.rdl-actions-right {
    margin-bottom: 10px;
}

.rdl-move {
    margin-bottom: 5px;
    border: 1px solid $rdl-btn-border;
    border-radius: 2px;
    background: $rdl-input-bg;
    cursor: pointer;
    padding: 5px 10px;
    color: $rdl-btn-color;
    font-size: 16px;
    &:active:not(:disabled),
    &:focus:not(:disabled) {
        border-color: darken($rdl-btn-border, 25%);
        background: darken($rdl-btn-bg, 10%);
    }
    &:focus:not(:disabled) {
        outline: thin dotted;
        outline-offset: -2px;
    }
    &:hover:not(:disabled) {
        border-color: var(--main-input-border-color) !important;
        background: #fff;
        color: var(--main-input-border-color)
    }
    &:disabled {
        opacity: .5;
        cursor: not-allowed;
    }
    &:last-child {
        margin-bottom: 0;
    } // Decrease spacing between icons
    i {
        margin: 0 -1px;
    }
}

.rdl-align-top {
    .rdl-available {
        margin-right: 10px;
    }
    .rdl-selected {
        margin-left: 10px;
    }
    .rdl-control {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
    .rdl-actions-left,
    .rdl-actions-right {
        flex-direction: row;
        margin: 0;
    }
    .rdl-move {
        flex: 0 1 50%;
        &:first-child {
            margin-bottom: 0;
            border-right: 0;
            border-top-right-radius: 0;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
        &:last-child {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }
}