.popover-confirm {
    .button-confirm {
        padding: 4px 8px;
        margin: 5px;
        border-radius: 3px;
        cursor: pointer;
        font-size: 13px;
        text-align: center;
        min-width: 50px;

        &.button-close {
            background: rgb(255, 255, 255);
            color: rgb(220, 53, 69);
            border: 1px solid rgb(220, 53, 69);
        }

        &.button-ok {
            color: rgb(255, 255, 255);
            background: rgb(0, 123, 255);
            border: 1px solid rgb(0, 123, 255);
        }
    }
}