.list-wrap {
  .base-filter {
    &:hover {
      box-shadow: 0 0 4px var(--main-input-border-color) !important;
    }
    margin: 0;
    position: relative;
    &.list-filter {
      outline: 0;
      color: #333;
      .list-item {
        cursor: default;
        padding: 0 6px;
        display: flex;
        align-items: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 14px;
        &:hover {
          color: #333;
          background: var(--main-color-hover-option-selected);
        }
        &-active {
          color: #fff;
          background: var(--main-color);
        }
      }
    }
  }
}