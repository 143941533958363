.month-range {
    .ant-picker {
        font-size: 13px;
        .ant-picker-input > input {
            font-size: 13px;
        }
        width: 100%;
        border: 0px !important;
        padding-right: 0px;
        .ant-picker-active-bar {
            display: none;
        }

        .ant-picker-clear {
            opacity: 1;
            right: 35px;
        }
    }
}

.ant-picker-cell-selected {
    color: #fff;
    .cell-month {
        background: var(--main-color);
    }
}

.ant-picker-dropdown {
    font-size: 13px;
    &.ant-picker-month {
        .ant-picker-cell-inner {
            text-transform: capitalize;
        }
    }

    &.ant-picker-single-month {
        .ant-picker-header-view {
            pointer-events: none;
            font-weight: bold;
        }

        .ant-picker-header-super-prev-btn,
        .ant-picker-header-super-next-btn {
            display: none;
        }
    }
}
