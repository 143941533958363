.treeSelect {
    /// New Version
    &.read-only {
        .ant-select-clear {
            display: none;
        }
    }

    .ant-tree-select {
        width: 100%;

        .ant-select-selector {
            // padding-right: 70px;
            // z-index: 9;
            background: #fff;
            padding: 0px 8px;
            cursor: pointer !important;
            .ant-select-selection-search {
                left: 8px;
            }

            .ant-select-selection-item {
                white-space: nowrap;
                overflow: hidden !important;
                text-overflow: ellipsis;
                display: inline-block;
                font-size: 13px;
                width: 1px;
                padding-right: 60px;
            }
        }
        .ant-select-arrow {
            height: 100%;
            top: 0;
            margin-top: 0px;
            right: 0px;
            width: 35px;
            display: flex;
            align-items: center;

            .anticon:not(.ant-select-suffix) {
                pointer-events: none;
            }
        }
        .ant-select-clear {
            opacity: 1;
            right: 35px;
            top: 0px;
            height: 100%;
            margin-top: 0px;
            width: 30px;
            z-index: 99;
        }

        .ant-select-selection-item-content {
            font-size: 12px;
        }
    }

    // Multiple
    .ant-select-multiple {
        .ant-select-selector {
            min-height: calc(var(--main-input-line-height) - 2px) !important;
            height: unset !important;

            .ant-select-selection-search {
                left: 0px;
                margin-left: 0px;
                .ant-select-selection-search-input {
                    margin-left: 0px;
                }
            }

            .ant-select-selection-item {
                white-space: nowrap;
                overflow: hidden !important;
                text-overflow: ellipsis;
                display: inline-flex;
                font-size: 13px;
                width: unset;
                max-width: 200px;
                padding-right: unset;
            }
        }

        .ant-select-clear {
            opacity: 1;
            right: 0px;
            top: 0px;
            height: 100%;
            margin-top: 0px;
            width: 30px;
            z-index: 99;
            display: flex;
            align-items: center;
        }

        .ant-select-selection-item {
            padding-right: 0px !important;
            .ant-select-selection-item-remove {
                width: 22px;
                &:hover {
                    background-color: #ffbdad;
                    color: #de350b;
                }
            }
        }
    }
}

.id-tree-select-dropdown {
    font-size: 13px;
    color: #333;

    .ant-select-tree-list-holder-inner {
        .ant-select-tree-treenode:first-child {
            .ant-select-tree-title {
                margin-top: -2px;
            }
        }
    }

    .ant-select-tree-treenode {
        display: flex;
        align-items: center;
        padding-bottom: 0px !important;
        padding-top: 10px !important;
    }

    .ant-select-tree-indent {
        margin-top: -10px !important;
    }

    .ant-select-tree-title {
        line-height: 16px;
        font-size: 13px;
        padding: 3px 0px;
    }

    .ant-select-tree-node-content-wrapper {
        line-height: 16px !important;
        padding: 0px 5px !important;
        display: flex;
        align-items: center;
    }

    .ant-select-tree-indent-unit {
        &::before {
            border: 0px !important;
        }

        display: inline-block;
        width: 30px;
        border-right: 1px dashed #888;
        margin-left: -17px;
        margin-top: -11px;
        height: 100%;

        &.ant-select-tree-indent-unit-end {
            &:not(:last-child) {
                border-right: 1px dashed #888;
            }
        }
    }

    &.id-tree-has-root-node {
        .ant-select-tree-indent-unit {
            &::before {
                border: 0px !important;
            }

            display: inline-block;
            width: 30px;
            border-right: 1px dashed #888;
            margin-left: -17px;
            margin-top: -11px;

            &.ant-select-tree-indent-unit-end {
                &:not(:last-child) {
                    border-right: 1px dashed #888;
                }
            }
            &.ant-select-tree-indent-unit-start {
                &:first-child {
                    border-right: 1px dashed #888;
                }
            }
        }

        .ant-select-tree-node-content-wrapper-open {
            position: relative;
            &::after {
                border-right: 1px dashed #888;
                width: 1px;
                left: -12px;
                right: 0px;
                bottom: 3px;
                top: calc(50% + 8px);
                content: "";
                position: absolute;
                z-index: 2;
            }
        }

        .ant-select-tree-list-holder-inner {
            .ant-select-tree-treenode-disabled:first-child {
                .ant-select-tree-switcher_open {
                    &::after {
                        border-right: 1px dashed #888;
                        width: 1px;
                        left: 12px;
                        right: 0px;
                        bottom: -23px;
                        top: 12px;
                        content: "";
                        position: absolute;
                        z-index: 1;
                    }
                }
            }
        }
    }

    .ant-select-tree-switcher-noop {
        svg {
            display: none !important;
        }
    }

    .ant-select-tree-switcher {
        position: relative;
        z-index: 2;

        &::before {
            border-bottom: 1px dashed #888;
            height: 1px;
            left: 0px;
            right: 0px;
            top: 12px;
            content: "";
            position: absolute;
            z-index: 1;
        }

        .ant-select-tree-switcher-line-icon {
            z-index: 99999;
            position: absolute;
            top: 5px;
            right: 4px;
            background: #fff;
        }
    }

    .ant-select-empty {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #333;
    }

    .ant-select-tree .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected,
    .ant-select-tree .ant-select-tree-node-content-wrapper:hover {
        background-color: var(--main-color-hover-option-selected) !important;
        color: var(--main-font-color-hover-option-selected) !important;
    }

    .ant-select-tree-treenode-disabled {
        .ant-select-tree-node-content-wrapper {
            pointer-events: none;
            background-color: #fff !important;
        }
    }

    .ant-select-tree-node-content-wrapper-open,
    .ant-select-tree-node-content-wrapper-close {
        .ant-select-tree-title {
            font-weight: bold;
        }
    }
}
