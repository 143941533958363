.label-field {
    font-weight: var(--form-label-field-weight);
    font-size: var(--main-label-font-size);
    color: var(--form-label-field-color);

    display: inline-block;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;

    &.label-field-top {
        margin-bottom: 5px;
    }

    &.label-image-field {
        .label-field-text {
            display: flex !important;
            flex-direction: column !important;
        }
        .extensions-field {
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            white-space: normal;
            font-style: italic;
            color: #555;
        }
    }
}

.input-field {
    flex: 1;
    display: flex;
    flex-direction: column;
    color: var(--main-input-font-color);
    font-size: var(--main-input-font-size);
    font-weight: var(--main-input-font-weight);
    height: 100%;
}

.error-field {
    width: var(--form-row-field-error-with);
    font-size: var(--main-error-font-size);
    color: var(--main-error-color);
    display: block;
    height: 20px;
    padding-top: 2px;
    overflow: hidden;
}

.required-field {
    font-size: 14px;
    color: red;
    padding: 0 3px;
}

.field-inside-error {
    .field-wrap {
        border: 1px solid red !important;
    }
}

.btn {
    height: 30px;
    border-radius: 3px;
    padding: 4px 8px;
    border: 1px solid transparent;
    cursor: pointer;
    outline: 0px;

    &.btn-primary,
    &.btn-success {
        color: #fff;
        background-color: #007bff;
        border-color: #007bff;

        &:hover {
            color: #007bff;
            background-color: #fff;
        }
    }

    &.btn-secondary {
        color: #fff;
        background-color: #6c757d;
        border-color: #6c757d;

        &:hover {
            color: #6c757d;
            background-color: #fff;
        }
    }

    &.btn-danger {
        color: #fff;
        background-color: #dc3545;
        border-color: #dc3545;

        &:hover {
            color: #dc3545;
            background-color: #fff;
        }
    }

    &.btn-warning {
        color: #212529;
        background-color: #ffc107;
        border-color: #ffc107;

        &:hover {
            color: #ffc107;
            background-color: #fff;
        }
    }

    &.btn-info {
        color: #fff;
        background-color: #17a2b8;
        border-color: #17a2b8;

        &:hover {
            color: #17a2b8;
            background-color: #fff;
        }
    }

    &.btn-light {
        color: #212529;
        background-color: #f8f9fa;
        border-color: #f8f9fa;

        &:hover {
            color: #212529;
            background-color: #fff;
        }
    }

    &.btn-dark {
        color: #fff;
        background-color: #343a40;
        border-color: #343a40;

        &:hover {
            color: #343a40;
            background-color: #fff;
        }
    }

    &.btn-link {
        font-weight: 400;
        color: #007bff;
        background-color: transparent;
    }

    &.btn-form {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 5px;
    }
}

.row-field {
    margin-bottom: 0px;
}

.toast-error {
    background-color: #d32f2f !important;
}
