// .date-picker-grid-wrap {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     border-radius: 5px;
//     border: 1px solid rgb(221, 221, 221);
//     background: rgb(255, 255, 255);
//     height: 32px;
//     width: 100%;

//     .date-picker-grid {
//         flex: 1;
//         height: 30px;

//         .ant-calendar-picker-input {
//             font-size: 13px;
//             border: 0 !important;
//             outline: 0 !important;
//             height: 30px;
//             line-height: 30px;

//             &:focus {
//                 box-shadow: unset !important;
//             }
//         }
//     }
// }

// .ant-calendar-input-wrap {
//     height: 32px !important;
// }

.id-grid-cell-renderer {
    height: 100%;
    width: calc(100% + 10px);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -5px;
    margin-right: -5px;

    &.date-picker-cell-renderer {
        .date-time-cell-range {
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            > div {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }

            > div:last-child {
                width: 150px;
            }
        }
        .body-wrap {
            height: calc(100% - 5px) !important;
            max-height: 32px;

            .ant-picker {
                height: 100% !important;

                .ant-picker-clear {
                    top: 2px;
                    bottom: 2px;
                    transform: translateY(0);
                    .icon-remove {
                        height: 100%;
                    }
                }
            }
        }
    }
}
