.dropdown-page-size {
    display: flex;
    align-items: center;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 3px;

    .rc-select-selection {
        border-radius: 3px;
    }

    .rc-select-arrow {
        height: 100%;
        display: flex;
        align-items: center;
        pointer-events: unset;
        cursor: pointer;
        position: relative;
        right: 5px !important;
    }

    &.rc-select-focused {
        div.rc-select-selector {
            border-color: #e9e9e9 !important;
        }
    }

    // Version 11
    .rc-select-selector {
        border: 0px solid #ddd !important;
        border-radius: 3px;
        height: 28px;

        .rc-select-selection-search-input {
            height: 100%;
        }

        .rc-select-selection-item {
            top: 0px;
            bottom: 0px;
            left: 8px;
            display: flex;
            align-items: center;
            font-size: 13px;
        }
    }
}

.dropdown-page-size-list {
    border-color: #e9e9e9 !important;

    div {
        max-height: none !important;
        overflow-y: unset !important;
    }

    .rc-select-item-option-state {
        right: 5px !important;
    }

    .rc-select-item-option-selected,
    .rc-select-item-option-active {
        background-color: var(--main-color-hover-option-selected);
        color: var(--main-font-color-hover-option-selected);
    }
}
