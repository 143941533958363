.rc-color-picker {
  z-index: 1051;
}

.color-picker-container {
  display: flex;
  align-items: center;
  min-width: 130px;
  position: relative;
  .color-picker-input {
    width: 100%;
    .icon-remove {
      position: absolute;
      right: 30px;
    }
  }
  .color-picker-handle {
    position: absolute;
    top: 0px;
    bottom: 0px;
    display: flex;
    align-items: center;
    right: 7px;
  }
  .color-picker-grey {
    opacity: 0.5;
  }
}
