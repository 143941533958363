.single-week {
    background: #fff;
    display: flex;
    .single-week-body {
        flex: 1;
    }
}

.week-picker {
    font-size: 13px;
    .ant-popover-inner-content {
        padding: 0px;
    }

    .week-cell {
        position: relative;
        min-width: 24px;
        font-weight: 400;
        height: 44px;
        display: flex;
        align-items: center;
        cursor: pointer;

        &.week-cell-active {
            .week-cell-value {
                background: var(--main-color);
                color: #fff;
            }
        }

        .week-cell-value {
            position: relative;
            z-index: 2;
            display: inline-block;
            min-width: 24px;
            height: 24px;
            line-height: 24px;
            border-radius: 2px;
            width: 60px;
            font-size: 13px;
            text-align: center;
            width: 88px;
            border-radius: 3px;
            color: #333333;

            &:hover {
                background: #f5f5f5;
            }
        }
    }
}
