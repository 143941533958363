.tree-drop-down-filter {
    width: 100%;
    height: 100%;

    .body-wrap {
        border: 0px !important;
    }

    .ant-tree-select {
        height: 100%;
        overflow: hidden;

        .ant-select-selector {
            height: 100% !important;
            min-height: 100%;
            overflow: hidden;
            .ant-select-selection-search {
                height: 100%;
                overflow: hidden;

                input {
                    height: 100%;
                }
            }
        }

        .ant-select-selection-item {
            height: 100%;
            line-height: 26px;
            font-weight: 400;
        }
    }
}
